@use "sass:map";

$grid-breakpoints: (
      xs: 0,
      sm: 360px,
      md: 768px,
      lg: 992px,
      xl: 1200px
) !default;

$theme-colors: (
        "primary": #4d5b75,
        "warning": #ffdc8b,
);

$border-radius: 10px;
$print-page-size: A4;
$font-family-base: 'Noto Sans', sans-serif;
$font-family-sans-serif: 'Noto Sans', sans-serif;
$font-size-base: 0.95rem;

@import "~bootstrap/scss/bootstrap";

.square-container {
  position: relative;
  width: 100%;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.force-background-color {
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
}

.tap-text {
  display: inline-block;
  padding-top: 4px;
  padding-bottom: 4px;
}

@include media-breakpoint-down(sm) {
  .tap-text {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

@media print {
  .tap-text {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.print-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 12px;
  background-color: map.get($theme-colors, "primary");
}

@media print {
  .print-header {
    display: none;
  }
}

ul {
  padding-inline-start: 20px;

  li {
    margin-bottom: $spacer * .5;
  }
}

.col-layout {
  > ul {
    margin-bottom: 0;
  }
}

$print-breakpoints: (
      xs: 0,
      sm: 360px,
      md: 768px,
      lg: 992px
);

@media print {
  @page {
    size: $print-page-size;
    margin: 0.4in 0.4in 0.4in 0.4in;
  }

  .print-page-break {
    page-break-after: always;
  }

  .print-avoid-break {
    page-break-inside: avoid;
  }

  @each $breakpoint in map-keys($print-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $print-breakpoints);

    @if $grid-columns > 0 {
      @for $i from 1 through $grid-columns {
        .col#{$infix}-#{$i} {
          @include make-col($i, $grid-columns);
        }
      }
    }

    @each $value in $displays {
      .d#{$infix}-#{$value} { display: $value !important; }
    }

    .text#{$infix}-left   { text-align: left !important; }
    .text#{$infix}-right  { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }
        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
        }
      }
    }

    .m#{$infix}-auto { margin: auto !important; }
    .mt#{$infix}-auto,
    .my#{$infix}-auto {
      margin-top: auto !important;
    }
    .mr#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-right: auto !important;
    }
    .mb#{$infix}-auto,
    .my#{$infix}-auto {
      margin-bottom: auto !important;
    }
    .ml#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-left: auto !important;
    }
  }
}